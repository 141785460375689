import Text, { TextSize } from 'bloko/blocks/text';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import ProfRoleExpandable from 'src/components/Employer/VacanciesGroupsByRegion/ProfRoleExpandable';
import Vacancies from 'src/components/Employer/VacanciesGroupsByRegion/Vacancies';
import { CountsByProfRole, FilterParams } from 'src/components/Employer/VacanciesGroupsByRegion/VacancyGroupsTypes';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';

const TrlKeys = {
    empty: 'employer.vacancies.none',
};

const VacanciesGroupsByRole: TranslatedComponent<{ filterParams: FilterParams }> = ({ trls, filterParams }) => {
    const employerId = useSelector(({ employerInfo }) => employerInfo.id);
    const { countsByProfRole, vacancies, count } = useSelector(
        ({ vacanciesGroupsByRegion }) => vacanciesGroupsByRegion.currentRegion
    );

    if (!employerId) {
        return null;
    }
    if (!count) {
        return (
            <div className="employer-vacancies-empty" data-qa="vacancies-empty-message">
                <Text size={TextSize.Large}>{trls[TrlKeys.empty]}</Text>
            </div>
        );
    }

    return (
        <div data-qa="vacancies-without-region-group">
            <div className="company-vacancy-indent" data-qa="vacancies-plain-list">
                <Vacancies vacanciesList={vacancies || []} />
            </div>
            {(countsByProfRole || []).map((profRole: CountsByProfRole) => (
                <ProfRoleExpandable
                    key={profRole.profRoleId}
                    id={profRole.profRoleId}
                    count={profRole.count}
                    hasNextPage={profRole.hasNextPage}
                    expanded={profRole.expanded}
                    firstPageLoaded={profRole.firstPageLoaded}
                    name={profRole.name}
                    vacancies={profRole.vacancies || []}
                    employerId={employerId}
                    currentPage={profRole.currentPage}
                    filterParams={filterParams}
                />
            ))}
        </div>
    );
};

export default translation(VacanciesGroupsByRole);
