import { PropsWithChildren, FC } from 'react';

import { NavigationBar, Action, VSpacing, GridLayout } from '@hh.ru/magritte-ui';
import { ChevronLeftOutlinedSize24 } from '@hh.ru/magritte-ui/icon';

import EmployerPageTitle, { TileMode } from 'src/components/Employer/PageTitle';
import { useSelector } from 'src/hooks/useSelector';

import { usePageTabsContext, EmployerPageTab } from 'src/components/Employer/PageTabs/PageTabsContext';
import PageTabsMobileOverlayContent from 'src/components/Employer/PageTabs/PageTabsMobileOverlayContent';

const DEFAULT_TAB = EmployerPageTab.Description;

const PageTabsMobileSupport: FC<PropsWithChildren> = ({ children }) => {
    const { setCurrentTab, currentTab, isEmployerTabsExp, isMobileView } = usePageTabsContext();
    const employerName = useSelector((state) => state.employerInfo.name);

    const isFullscreenLayout = isEmployerTabsExp && isMobileView && currentTab !== DEFAULT_TAB;

    if (!isFullscreenLayout) {
        return <>{children}</>;
    }

    return (
        <EmployerPageTitle mode={TileMode.View} employerName={employerName}>
            <VSpacing default={8} />
            <NavigationBar
                title={employerName}
                left={<Action icon={ChevronLeftOutlinedSize24} onClick={() => setCurrentTab(DEFAULT_TAB)} />}
            >
                <GridLayout>
                    <PageTabsMobileOverlayContent />
                </GridLayout>
            </NavigationBar>
        </EmployerPageTitle>
    );
};

export default PageTabsMobileSupport;
