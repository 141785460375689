import { FC } from 'react';

import { useBreakpoint, VSpacing } from '@hh.ru/magritte-ui';

import { useSelector } from 'src/hooks/useSelector';

import SearchOrder from 'src/components/Employer/VacancySearch/FiltersForm/SearchOrder';
import SuggestForm from 'src/components/Employer/VacancySearch/FiltersForm/SuggestForm';
import VacancyCount from 'src/components/Employer/VacancySearch/FiltersForm/VacancyCount';

import styles from './filter-form.less';

const FiltersForm: FC = () => {
    const { isMobile } = useBreakpoint();
    const vacanciesCount = useSelector((state) => state.activeEmployerVacancyCount);
    const isLoading = useSelector(({ employerVacancySearch }) => employerVacancySearch.isLoading);

    if (!vacanciesCount) {
        return null;
    }

    return (
        <>
            <SuggestForm />
            <VSpacing gteS={24} default={16} />
            {!isLoading && (
                <>
                    <div className={styles.filtersFormOrderBy}>
                        {!isMobile && <SearchOrder />}
                        <div className={styles.filtersFormDummy} />
                        <VacancyCount />
                    </div>
                    <VSpacing gteS={12} default={16} />
                </>
            )}
        </>
    );
};

export default FiltersForm;
