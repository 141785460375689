import { useRef, useCallback, FormEvent } from 'react';

import vacancySearchLineFormSubmit, {
    HhtmSource,
} from '@hh.ru/analytics-js-events/build/xhh/common/vacancy_search/vacancy_search_line_form_submit';
import { Button, useBreakpoint, HSpacingContainer } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import useSetEmployerVacancies from 'src/api/employerVacancySearch/useSetEmployerVacancies';
import Form from 'src/components/Form';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { CriteriaKey } from 'src/models/search/searchCriteria.types';

import Filters from 'src/components/Employer/VacancySearch/FiltersForm/Filters';
import SearchSuggest from 'src/components/Employer/VacancySearch/FiltersForm/SearchSuggest';

import styles from './suggest-form.less';

const TrlKeys = {
    button: 'employer.vacancy.search.button',
};

const SuggestForm: TranslatedComponent = ({ trls }) => {
    const { isMobile } = useBreakpoint();
    const formRef = useRef<HTMLFormElement>(null);
    const { setVacanciesByCriteria } = useSetEmployerVacancies();
    const hhtmSource = useSelector((state) => state.analyticsParams.hhtmSource as HhtmSource);

    const onFormSubmit = useCallback(() => {
        formRef.current?.requestSubmit();
    }, []);

    const onSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (formRef.current) {
            const formData = new FormData(formRef.current);
            const entries = Object.fromEntries(formData.entries());
            setVacanciesByCriteria(entries);
            const text = entries?.[CriteriaKey.Text];
            typeof text === 'string' && vacancySearchLineFormSubmit({ text, hhtmSource });
        }
    };

    return (
        <div className={styles.suggestFormWrapper}>
            <div className={styles.suggestForm}>
                <Form onSubmit={onSubmit} method={'GET'} ref={formRef}>
                    <HSpacingContainer default={12}>
                        <SearchSuggest onFormSubmit={onFormSubmit} />
                        {!isMobile && (
                            <Button type="submit" mode="primary" style="accent">
                                {trls[TrlKeys.button]}
                            </Button>
                        )}
                    </HSpacingContainer>
                </Form>
            </div>
            <Filters />
        </div>
    );
};

export default translation(SuggestForm);
