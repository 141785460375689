import { useRef, FC, useEffect } from 'react';

import { VSpacing } from '@hh.ru/magritte-ui';

import { useSetEmployerVacancyListDefault } from 'src/api/employerVacancySearch/useSetEmployerVacancies';
import { usePageTabsContext, EmployerPageTab } from 'src/components/Employer/PageTabs/PageTabsContext';
import MagritteWrapper from 'src/components/MagritteWrapper/MagritteWrapper';
import NovaFilterUpdateContextProvider from 'src/components/NovaFilters/NovaFilterUpdateContext';

import FiltersForm from 'src/components/Employer/VacancySearch/FiltersForm';
import SearchContent from 'src/components/Employer/VacancySearch/SearchContent';

const VacancySearch: FC = () => {
    const widgetRef = useRef<HTMLDivElement>(null);
    const { currentTab, isEmployerTabsExp, hasPreviusTab } = usePageTabsContext();
    const prevTabRef = useRef<EmployerPageTab | null>(null);
    const { setEmployerVacancyListDefault } = useSetEmployerVacancyListDefault();
    const scrollWidgetTop = () => {
        widgetRef.current && widgetRef.current.scrollIntoView();
    };

    useEffect(() => {
        if (hasPreviusTab && prevTabRef.current !== currentTab) {
            currentTab === EmployerPageTab.Vacancies && setEmployerVacancyListDefault();
            prevTabRef.current = currentTab;
        }
    }, [currentTab, setEmployerVacancyListDefault, hasPreviusTab, prevTabRef]);

    return (
        <MagritteWrapper isEnabled>
            <NovaFilterUpdateContextProvider>
                {!isEmployerTabsExp && <VSpacing default={28} />}
                <div ref={widgetRef}>
                    <FiltersForm />
                    <SearchContent scrollWidgetTop={scrollWidgetTop} />
                </div>
            </NovaFilterUpdateContextProvider>
        </MagritteWrapper>
    );
};

export default VacancySearch;
