import classnames from 'classnames';

import HoverTip, { TipLayer } from 'bloko/blocks/drop/Tip/HoverTip';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';
import Widget, { AddWidgetButtonType } from 'src/models/employerConstructor/widget.types';

import AddWidgetButtonTip from 'src/components/EmployerConstructor/AddWidgetButtonTip';

const TrlKeys = {
    [Widget.Text]: 'employer.constructor.widget.type.text',
    [Widget.Picture]: 'employer.constructor.widget.type.picture',
    [Widget.Address]: 'employer.constructor.widget.type.address',
    [Widget.Gallery]: 'employer.constructor.widget.type.gallery',
    [Widget.Video]: 'employer.constructor.widget.type.video',
    [Widget.Separator]: 'employer.constructor.widget.type.separator',
};

interface AddWidgetButtonProps {
    onClick: () => void;
    type: AddWidgetButtonType;
    count: number;
    limit?: number;
}

const AddWidgetButton: TranslatedComponent<AddWidgetButtonProps> = ({ onClick, type, limit, count, trls }) => {
    const typeLower = type.toLowerCase();
    const disabled = limit && count >= limit;
    const imageClassName = `add-widget-type__image_${typeLower}`;

    const renderIcon = (
        <div
            data-qa={`add-widget-${typeLower}`}
            className={classnames('add-widget-type', {
                'add-widget-type_limit': disabled,
                'add-widget-type_active': !disabled,
            })}
            onClick={() => {
                if (!disabled) {
                    onClick();
                }
            }}
        >
            <div className={`add-widget-type__image ${imageClassName}`} />
            {trls[TrlKeys[type]]}
        </div>
    );
    return (
        <HoverTip
            host={!process.env.LUX_SERVER ? document.body : null}
            render={() => <AddWidgetButtonTip count={count} limit={limit} type={type} />}
            layer={TipLayer.Overlay}
        >
            {renderIcon}
        </HoverTip>
    );
};

export default translation(AddWidgetButton);
