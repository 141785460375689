import { FC } from 'react';

import VacancySearch from 'src/components/Employer/VacancySearch';
import useExperiment from 'src/hooks/useExperiment';
import { useSelector } from 'src/hooks/useSelector';

import VacanciesGroupsByRegion from 'src/components/Employer/VacanciesGroupsByRegion/VacanciesGroupsByRegion';

const VacanciesBlock: FC<{ hasChameleon?: boolean }> = (props) => {
    const showEmployerVacancySearch = useSelector(({ showEmployerVacancySearch }) => showEmployerVacancySearch);
    // если пользователь не переключит вкладку он не увидит эксперимент
    // поэтому включаем пользователя в эксп здесь
    return useExperiment('employer_vacancy_search_widget', showEmployerVacancySearch) ? (
        <VacancySearch />
    ) : (
        <VacanciesGroupsByRegion {...props} />
    );
};

export default VacanciesBlock;
