import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import classnames from 'classnames';

import Link, { LinkAppearance } from 'bloko/blocks/link';
import Loading, { LoadingScale } from 'bloko/blocks/loading';
import Text, { TextImportance } from 'bloko/blocks/text';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';
import {
    addVacanciesToProfRole,
    setProfRoleExpanded,
    firstPageLoadedWithRoles,
} from 'src/models/vacanciesGroupsByRegion';
import fetcher from 'src/utils/fetcher';

import Vacancies from 'src/components/Employer/VacanciesGroupsByRegion/Vacancies';
import {
    DataResponseVacancies,
    REGION_PROP_NAMES,
    RegionTypes,
    Vacancy,
    FilterParams,
} from 'src/components/Employer/VacanciesGroupsByRegion/VacancyGroupsTypes';

const VACANCIES_LIST_URL = '/shards/employerview/vacancies_list';

const TrlKeys = {
    more: 'more',
};

interface Params {
    page: number;
    profRole?: number;
    profCategory?: number;
    currentEmployerId: number;
    json: boolean;
    regionType?: string;
}

declare global {
    interface FetcherGetApi {
        [VACANCIES_LIST_URL]: {
            queryParams: Params;
            response: DataResponseVacancies;
        };
    }
}
interface ProfRoleExpandableProps {
    id: number;
    count: number;
    name: string;
    employerId: number;
    currentPage?: number;
    hasNextPage: boolean;
    vacancies: Array<Vacancy>;
    regionType?: RegionTypes;
    expanded?: boolean;
    firstPageLoaded?: boolean;
    filterParams?: FilterParams;
}

const ProfRoleExpandable: TranslatedComponent<ProfRoleExpandableProps> = ({
    employerId,
    currentPage = 0,
    hasNextPage,
    regionType,
    id,
    name,
    count,
    expanded,
    firstPageLoaded,
    vacancies,
    trls,
    filterParams,
}) => {
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();

    const loadVacancies = useCallback(async () => {
        setLoading(true);
        await fetcher
            .get(VACANCIES_LIST_URL, {
                params: {
                    page: currentPage,
                    profRole: id,
                    currentEmployerId: employerId,
                    json: true,
                    regionType: regionType ? REGION_PROP_NAMES[regionType] : undefined,
                    ...(filterParams || {}),
                },
            })
            .then((data) => {
                dispatch(
                    addVacanciesToProfRole({
                        profRoleId: id,
                        regionType: regionType || RegionTypes.Current,
                        data: { ...data, currentPage: currentPage + 1 },
                    })
                );

                if (!currentPage) {
                    dispatch(
                        firstPageLoadedWithRoles({ profRoleId: id, regionType: regionType || RegionTypes.Current })
                    );
                }
            }, console.error);
        setLoading(false);
    }, [currentPage, id, employerId, regionType, filterParams, dispatch]);

    const toggleWrapper = useCallback(() => {
        dispatch(
            setProfRoleExpanded({
                profRoleId: id,
                expanded: !expanded,
                regionType: regionType || RegionTypes.Current,
            })
        );
        if (expanded || firstPageLoaded || loading) {
            return;
        }
        void loadVacancies();
    }, [dispatch, expanded, firstPageLoaded, id, loadVacancies, regionType, loading]);

    return (
        <div
            className={classnames('company-vacancies-group', {
                'company-vacancies-group_expanded': expanded,
            })}
            data-qa={`vacancies-in-prof-role vacancies-in-prof-role_${id}`}
        >
            <div className="company-vacancies-group__title">
                <Link
                    appearance={LinkAppearance.Pseudo}
                    onClick={toggleWrapper}
                    data-qa="vacancies-in-prof-role-switch"
                    icon={
                        <span className="company-vacancies-hint" data-qa="vacancies-in-prof-role-counter">
                            <Text Element="span" importance={TextImportance.Tertiary}>
                                {count}
                            </Text>
                        </span>
                    }
                >
                    {name}
                </Link>
            </div>
            <div className="company-vacancies-group__list">
                <Vacancies vacanciesList={vacancies}>
                    <span className="company-vacancies-group__more-button-wrapper">
                        {hasNextPage && !loading && (
                            <Link
                                appearance={LinkAppearance.Pseudo}
                                onClick={loadVacancies}
                                data-qa="vacancies-more-button"
                            >
                                {trls[TrlKeys.more]}
                            </Link>
                        )}
                        {loading && <Loading scale={LoadingScale.Small} />}
                    </span>
                </Vacancies>
            </div>
        </div>
    );
};

export default translation(ProfRoleExpandable);
