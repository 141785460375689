import { FC } from 'react';

import { Layer, LayerName } from '@hh.ru/magritte-ui';

import { usePageTabsContext, EmployerPageTab } from 'src/components/Employer/PageTabs/PageTabsContext';
import MagritteWrapper from 'src/components/MagritteWrapper/MagritteWrapper';
import EmployerVacancySearchScrollToTop from 'src/components/SearchScrollToTop/EmployerVacancy';
import useIsEmployerVacancySearch from 'src/models/employerVacancySearch/useIsEmployerVacancySearch';

import styles from './search-footer-button.less';

const SearchFooterButton: FC = () => {
    const { currentTab, isEmployerTabsExp } = usePageTabsContext();
    const isEmployerVacancySearch = useIsEmployerVacancySearch();

    if (!isEmployerVacancySearch || !isEmployerTabsExp || currentTab !== EmployerPageTab.Vacancies) {
        return null;
    }

    return (
        <Layer layer={LayerName.Alpha}>
            <div className={styles.searchFooterButton}>
                <MagritteWrapper isEnabled>
                    <EmployerVacancySearchScrollToTop />
                </MagritteWrapper>
            </div>
        </Layer>
    );
};

export default SearchFooterButton;
