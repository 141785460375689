import { Dispatch, AnyAction } from 'redux';

import { setDescription, setDescriptionStatus, Status } from 'src/models/employer/employerInfo';
import fetcher from 'src/utils/fetcher';

const SAVE_EMPLOYER_DESCRIPTION_URL = '/employer/description';

declare global {
    interface FetcherPostApi {
        [SAVE_EMPLOYER_DESCRIPTION_URL]: {
            body: { description: string | null };
            queryParams: void;
            response: { description: string };
        };
    }
}

export default (payload: string | null) =>
    (dispatch: Dispatch<AnyAction>): Promise<void> => {
        dispatch(setDescriptionStatus(Status.Fetching));
        return fetcher.postFormData(SAVE_EMPLOYER_DESCRIPTION_URL, { description: payload }).then(
            ({ data }) => {
                dispatch([setDescription(data.description), setDescriptionStatus(Status.Success)]);
            },
            (error) => {
                dispatch(setDescriptionStatus(Status.Fail));
                throw error;
            }
        );
    };
