import { useRef } from 'react';

import { Button, Drop, useBreakpoint, Text, Action, VSpacing } from '@hh.ru/magritte-ui';
import { AdjustmentsOutlinedSize24, CrossOutlinedSize24 } from '@hh.ru/magritte-ui/icon';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import NovaFilters from 'src/components/NovaFilters';
import MobileForm from 'src/components/NovaFilters/components/Magritte/MobileForm';
import useToggleSearchPreference from 'src/components/SearchSorts/hooks/useToggleSearchPreference';
import novaFiltersOrder from 'src/components/VacancySearchContent/components/novaFiltersOrder';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';

import styles from './filters.less';

const TrlKeys = {
    filtersDropHeader: 'employer.vacancy.search.filters.header',
};

const Filters: TranslatedComponent = ({ trls }) => {
    const activatorRef = useRef(null);
    const totalUsedFilters = useSelector(({ employerVacancySearch }) => employerVacancySearch.totalUsedFilters);
    const criteria = useSelector(({ employerVacancySearch }) => employerVacancySearch.criteria);
    const toggleSearchPreference = useToggleSearchPreference();
    const showSearchPreference = useSelector(({ searchPreference }) => searchPreference.isShown);
    const { isMobile } = useBreakpoint();
    if (!criteria) {
        return null;
    }
    return (
        <>
            <Drop
                visible={showSearchPreference}
                onClose={() => toggleSearchPreference(!showSearchPreference)}
                activatorRef={activatorRef}
                role="status"
                placement="bottom-right"
                forcePlacement
                space={600}
                maxWidth={480}
            >
                <div className={styles.filtersDrop}>
                    <div className={styles.filtersDropHeader}>
                        <Text typography="title-4-semibold">{trls[TrlKeys.filtersDropHeader]}</Text>
                        <Action
                            mode="secondary"
                            onClick={() => toggleSearchPreference(false)}
                            icon={CrossOutlinedSize24}
                            disablePadding
                        />
                    </div>
                    <VSpacing default={24} />
                    <NovaFilters criteria={criteria} filtersOrder={novaFiltersOrder} isMagritte />
                </div>
            </Drop>
            <Button
                ref={activatorRef}
                postfix={totalUsedFilters || ''}
                style="neutral"
                mode="secondary"
                hideLabel
                aria-label={trls[TrlKeys.filtersDropHeader]}
                icon={<AdjustmentsOutlinedSize24 />}
                onClick={() => toggleSearchPreference(!showSearchPreference)}
            />
            {isMobile && (
                <MobileForm>
                    <NovaFilters criteria={criteria} filtersOrder={novaFiltersOrder} withSorting isMagritte />
                </MobileForm>
            )}
        </>
    );
};

export default translation(Filters);
