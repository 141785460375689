import { FC } from 'react';

import VacanciesFilter from 'src/components/Employer/VacanciesFilter';
import VacanciesGroupsByRegion from 'src/components/Employer/VacanciesGroupsByRegion';
import { useSelector } from 'src/hooks/useSelector';

import { usePageTabsContext, EmployerPageTab } from 'src/components/Employer/PageTabs/PageTabsContext';

const PageTabsMobileOverlayContent: FC = () => {
    const { currentTab } = usePageTabsContext();
    const hasChameleon = useSelector((state) => state.hasChameleon);

    if (currentTab !== EmployerPageTab.Vacancies) {
        return null;
    }

    if (hasChameleon) {
        return <VacanciesFilter />;
    }

    return <VacanciesGroupsByRegion hasChameleon />;
};

export default PageTabsMobileOverlayContent;
